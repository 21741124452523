import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import './Home.css';

import '@splidejs/react-splide/css';
import Splide from '@splidejs/splide';

const Home = () => {

  useEffect(() => {
    document.title = "Faith Navigator";
    // Initialize Splide slider
    // new Splide('.splide', {
    //   type: 'loop', // Example of configuration, you can add more options here
    //   perPage: 1, // Number of slides per page
    //   autoplay: true, // Enable autoplay
    //   interval: 5000, // Delay between slides (in ms)
    //   pagination: false,      
    //   arrows: false
    // }).mount();
  }, []); // Empty array ensures this runs once after the component is mounted

  return (

    <header>
 
    <div className="container d-flex justify-content-center">
        <div className="content-container">
          <main>
            <img src='faith_logo_full.png' className='logo-header'/>  
            <h1>Welcome to the most powerful faith and Bible AI assistant available!</h1>
            <p>Faith Navigator is more than just an app — it's a spiritual guide designed to deepen your understanding of the Bible and strengthen your faith journey. Seamlessly integrating with the scriptures, this powerful AI assistant helps you explore moral questions, biblical teachings, church history, and more, all at your fingertips.
            </p>
           
        
          </main>
        </div>
        <div className="bottom-of-screen">
              <a href='https://apps.apple.com/app/faith-navigator/id6503354903' target='new'>
              <img src="app_store.png" width={140}/><br/><br/>
               </a>
              
              <a href="mailto:support@zupko.org" className="btn btn-secondary"  > Contact Support</a>
              <div>

<Link to="/privacy-policy" className="small-white-link">Privacy Policy</Link>
<Link to="/terms-of-service" className="small-white-link">Terms of Service</Link>
</div>
              </div>
             

      </div>
    </header>
  );
};

export default Home;



            {/* <div>
            <section className="splide" aria-label="Faith Navigator">
            
              <div className="splide__track">
                <ul className="splide__list">
                  <li className="splide__slide">
                    <img src="app_chat.png" alt="Chat" width={300}/>
                    <br/>
                   <p>AI chat seamlessly links with scripture and religious sources, providing spiritual guidance and insights.
                    </p>
                  </li>
                  <li className="splide__slide"><img src="app_devotionals.png" alt="Devotionals" width={300}/></li>
                  <li className="splide__slide"><img src="app_bible.png" alt="Scripture" width={300}/></li>
                  <li className="splide__slide"><img src="app_help.png" alt="More!" width={300}/></li>
                </ul>
                <br/>
              </div>
            </section>
            </div> */}