import React, { useEffect } from 'react';

const PrivacyPolicy = () => {
  useEffect(() => {
    document.title = "Privacy Policy";

  }, []); // Empty array ensures this runs once after the component is mounted

  return (
    <div className="container d-flex">
        <div className="privacy-container">
          <h1>Privacy Policy</h1>
    <p><strong>Effective Date:</strong> 06/30/24</p>

    <h2>1. Introduction</h2>
    <p>Welcome to Faith Navigator ("we," "our," or "us"). We are committed to protecting your privacy and ensuring that your personal information is handled in a safe and responsible manner. This Privacy Policy explains how we collect, use, disclose, and protect your information when you use our AI faith and Bible assistant app (the "App").</p>

    <h2>2. Information We Collect</h2>
    <p>We may collect the following types of information:</p>
    <ul>
        <li><strong>Personal Information:</strong> When you create an account or interact with the App, we may collect personal information such as your name, email address, and other contact details.</li>
        <li><strong>Usage Data:</strong> We may collect information about your usage of the App, including interactions with features, pages visited, and other usage patterns.</li>
        <li><strong>Device Information:</strong> We may collect information about the device you use to access the App, including device type, operating system, and unique device identifiers.</li>
        <li><strong>Log Data:</strong> Our servers may automatically collect log data when you use the App, including your IP address, browser type, and access times.</li>
    </ul>

    <h2>3. How We Use Your Information</h2>
    <p>We may use the information we collect for various purposes, including:</p>
    <ul>
        <li>To provide, operate, and maintain the App</li>
        <li>To improve, personalize, and expand the App</li>
        <li>To communicate with you, including sending updates and promotional materials</li>
        <li>To process transactions and manage your account</li>
        <li>To detect, prevent, and address technical issues</li>
        <li>To analyze usage and trends to improve the App</li>
    </ul>

    <h2>4. Sharing Your Information</h2>
    <p>We do not sell, trade, or otherwise transfer your personal information to outside parties except as described in this Privacy Policy. We may share your information with:</p>
    <ul>
        <li><strong>Service Providers:</strong> Third-party vendors who help us operate and maintain the App</li>
        <li><strong>Legal Requirements:</strong> If required by law, regulation, or legal process, we may disclose your information</li>
        <li><strong>Business Transfers:</strong> In the event of a merger, acquisition, or sale of assets, your information may be transferred</li>
    </ul>

    <h2>5. Data Security</h2>
    <p>We implement reasonable security measures to protect your information from unauthorized access, alteration, disclosure, or destruction. However, please be aware that no security measures are perfect or impenetrable.</p>

    <h2>6. Your Choices</h2>
    <p>You have the right to:</p>
    <ul>
        <li>Access and update your personal information</li>
        <li>Opt-out of receiving promotional communications</li>
        <li>Delete your account and personal information</li>
        <li>Restrict or object to certain data processing activities</li>
    </ul>
    <p>To exercise these rights, please contact us at [Your Contact Information].</p>

    <h2>7. Children's Privacy</h2>
    <p>Our App is not intended for children under the age of 13. We do not knowingly collect personal information from children under 13. If we become aware that a child under 13 has provided us with personal information, we will take steps to delete such information.</p>

    <h2>8. Changes to This Privacy Policy</h2>
    <p>We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page. You are advised to review this Privacy Policy periodically for any changes.</p>

    <h2>9. Contact Us</h2>
    <p>If you have any questions or concerns about this Privacy Policy, please contact us at <a href="mailto:support@zupko.org">Support</a>.</p>
    </div>
    </div>
  );
};

export default PrivacyPolicy;
