import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

const TermsOfService = () => {

  useEffect(() => {
    document.title = "Terms of Service";

  }, []); // Empty array ensures this runs once after the component is mounted

  return (
    <div className="container d-flex">
        <div className="privacy-container">
        <h1>Terms of Service</h1>

<h2>1. Acceptance of Terms</h2>
<p>By accessing and using Faith Navigator ("the App"), you accept and agree to be bound by the terms and provisions of this agreement. Additionally, when using these particular services, you shall be subject to any posted guidelines or rules applicable to such services. Any participation in this service will constitute acceptance of this agreement. If you do not agree to abide by the above, please do not use this service.</p>

<h2>2. Description of Service</h2>
<p>Faith Navigator provides users with an AI-assisted Bible study tool designed to help users study scriptures, provide spiritual assistance, and offer guidance and teaching about the Christian faith ("the Service"). The App may include, but is not limited to, personalized study plans, scripture analysis, and other related features.</p>

<h2>3. User Responsibilities</h2>
<p>Users agree not to use the Service for any unlawful or prohibited activities.</p>
<p>Users agree to use the Service in a manner that is respectful and consistent with the values of the Christian faith.</p>

<h2>4. Subscription and Payments</h2>
<p>Faith Navigator may offer certain features or services through a subscription model. Details of the subscription plans, including pricing, duration, and features, will be provided within the App.</p>
<p>Users agree to provide accurate and complete payment information and authorize Faith Navigator to charge the provided payment method for any applicable subscription fees.</p>
<p>Subscription fees are non-refundable except as required by law.</p>

<h2>5. Content and Intellectual Property</h2>
<p>All content provided through the Service, including but not limited to text, graphics, images, and software, is the property of Faith Navigator or its content suppliers and is protected by applicable intellectual property laws.</p>
<p>Users are granted a limited, non-exclusive, non-transferable license to access and use the Service for personal, non-commercial purposes.</p>
<p>Users agree not to copy, distribute, modify, or create derivative works from any content provided through the Service without prior written consent from Faith Navigator.</p>

<h2>6. Privacy Policy</h2>
<p>Faith Navigator respects the privacy of its users. Please refer to our <Link to="/privacy-policy" className="btn btn-primary">Privacy Policy</Link> for details on how we collect, use, and protect your personal information.</p>

<h2>7. Disclaimer of Warranties</h2>
<p>The Service is provided on an "as is" and "as available" basis. Faith Navigator makes no warranties, expressed or implied, and hereby disclaims and negates all other warranties, including without limitation, implied warranties or conditions of merchantability, fitness for a particular purpose, or non-infringement of intellectual property or other violation of rights.</p>

<h2>8. Limitation of Liability</h2>
<p>In no event shall Faith Navigator or its suppliers be liable for any damages (including, without limitation, damages for loss of data or profit, or due to business interruption) arising out of the use or inability to use the Service, even if Faith Navigator has been notified orally or in writing of the possibility of such damage.</p>

<h2>9. Changes to the Terms of Service</h2>
<p>Faith Navigator reserves the right to modify these Terms of Service at any time. Users will be notified of any changes through the App or via email. Continued use of the Service after any such changes shall constitute your consent to such changes.</p>

<h2>10. Governing Law</h2>
<p>These Terms of Service are governed by and construed in accordance with the laws of your country/state, and you irrevocably submit to the exclusive jurisdiction of the courts in that State or location.</p>

<h2>11. Contact Information</h2>
<p>For any questions or concerns regarding these Terms of Service, please contact us.</p>

    </div>
    </div>
  );
};

export default TermsOfService;
